import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiServiceService } from './api-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'foodDeliveryAdminpanel';
  country = 'india'
   constructor(private translate: TranslateService,public service : ApiServiceService) {
   this.setLanguage();
   if(localStorage.getItem("token")){

     this.service.getApi("chat/user-detail",1).subscribe((res)=>{
      localStorage.setItem("myUserId",res.body.id)
     },(error)=>{
      console.log("error",error);
      
     })
   }
}

setLanguage() {
  this.translate.addLangs(['en', 'th']);
  !!this.country ? (this.country.toLowerCase() === 'thailand' ? this.translate.setDefaultLang('th') 
  :  this.translate.setDefaultLang('en')) 
  : this.translate.setDefaultLang('en');
  }
}
