import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-driver-rejected-kyc',
  templateUrl: './driver-rejected-kyc.component.html',
  styleUrls: ['./driver-rejected-kyc.component.css']
})
export class DriverRejectedKycComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
