import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { MenubarComponent } from './menubar/menubar.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
// import { ToastrModule } from 'ng6-toastr-notifications';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxCaptchaModule } from 'ngx-captcha';
import { RecaptchaModule } from 'ng-recaptcha';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { NgxSpinnerModule } from "ngx-spinner";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxPopoverImageModule } from 'ngx-popover-image';
import { RatingModule } from "ngx-rating";
import { NgOtpInputModule } from 'ng-otp-input';
import { ToastrModule } from 'ngx-toastr';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown"
// import { IntlInputPhoneModule } from 'intl-input-phone';
import { NgSelectModule } from '@ng-select/ng-select';
// import { CKEditorModule } from 'ng2-ckeditor';
import { CKEditorModule } from 'ngx-ckeditor';

// #############################COMPONENT START ##############################//

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ManageMerchantComponent } from './manage-merchant/manage-merchant.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MerchantDetailsComponent } from './merchant-details/merchant-details.component';
import { AddMerchantComponent } from './add-merchant/add-merchant.component';
import { ManageDriversComponent } from './manage-drivers/manage-drivers.component';
import { DriverDetailsComponent } from './driver-details/driver-details.component';
import { LoginVerificationComponent } from './login-verification/login-verification.component';
import { SubmitKycComponent } from './submit-kyc/submit-kyc.component';
import { AddNewRoleComponent } from './add-new-role/add-new-role.component';
import { ManageRoleComponent } from './manage-role/manage-role.component';
import { ViewKycComponent } from './view-kyc/view-kyc.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DriverApprovedKycComponent } from './driver-approved-kyc/driver-approved-kyc.component';
import { DriverRejectedKycComponent } from './driver-rejected-kyc/driver-rejected-kyc.component';
import { ViewDriverKycComponent } from './view-driver-kyc/view-driver-kyc.component';
import { PageNotfoundComponent } from './page-notfound/page-notfound.component';
import { ChangePasswordVerificationComponent } from './change-password-verification/change-password-verification.component';
import { CustomerManagmentComponent } from './customer-managment/customer-managment.component';
import { ViewCustomerDetailsComponent } from './view-customer-details/view-customer-details.component';
import { RegisterMerchantComponent } from './register-merchant/register-merchant.component';
import { WithoutLoginHeaderComponent } from './without-login-header/without-login-header.component';
import { RegisterDriverComponent } from './register-driver/register-driver.component';
import { DriverSubmitKycComponent } from './driver-submit-kyc/driver-submit-kyc.component';
import { ExcelService } from './services/excel.service';
import { ManageStaffComponent } from './manage-staff/manage-staff.component';
import { FooterComponent } from './footer/footer.component';
import { AddStaffComponent } from './add-staff/add-staff.component';
import { EditStaffComponent } from './edit-staff/edit-staff.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { ManageReportComponent } from './manage-report/manage-report.component';
import { StaffViewComponent } from './staff-view/staff-view.component';
import { RolePermissionComponent } from './role-permission/role-permission.component';
import { ManageSubscriptionComponent } from './manage-subscription/manage-subscription.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { ManageSaleComponent } from './manage-sale/manage-sale.component';
import { ManageOfferComponent } from './manage-offer/manage-offer.component';

import { AddSalerepresentativeComponent } from './add-salerepresentative/add-salerepresentative.component';
import { AddOfferComponent } from './add-offer/add-offer.component';
import { ViewOfferComponent } from './view-offer/view-offer.component';
import { ManageCousinsComponent } from './manage-cousins/manage-cousins.component';
import { AddCousinsComponent } from './add-cousins/add-cousins.component';
import { ManageAllergnComponent } from './manage-allergn/manage-allergn.component';
import { AddAllergnComponent } from './add-allergn/add-allergn.component';
import { EditCusineComponent } from './edit-cusine/edit-cusine.component';
import { PayoutDriverComponent } from './payout-driver/payout-driver.component';
import { PayoutDriverDetailsComponent } from './payout-driver-details/payout-driver-details.component';
import { PayoutSalesDetailsComponent } from './payout-sales-details/payout-sales-details.component';
import { ViewAllerganComponent } from './view-allergan/view-allergan.component';
import { EditAllerganComponent } from './edit-allergan/edit-allergan.component';
import { LegalTermComponent } from './legal-term/legal-term.component';
import { StaticContentComponent } from './static-content/static-content.component';
import { OrderViewComponent } from './order-view/order-view.component';
import { DeliveryChargeComponent } from './delivery-charge/delivery-charge.component';
import { ChangeDeliveryChargesComponent } from './change-delivery-charges/change-delivery-charges.component';
import { ManageFaqComponent } from './manage-faq/manage-faq.component';
import { AddFaqComponent } from './add-faq/add-faq.component';
import { EditFaqComponent } from './edit-faq/edit-faq.component';
import { TopicManagementComponent } from './topic-management/topic-management.component';
import { AddTopicComponent } from './add-topic/add-topic.component';
import { EditTopicComponent } from './edit-topic/edit-topic.component';
import { HelpQuestionComponent } from './help-question/help-question.component';
import { AddHelpQuestionComponent } from './add-help-question/add-help-question.component';
import { EditHelpQuestionComponent } from './edit-help-question/edit-help-question.component';
import { BlogManagementComponent } from './blog-management/blog-management.component';
import { AddBlogComponent } from './add-blog/add-blog.component';
import { EditBlogComponent } from './edit-blog/edit-blog.component';
import { SupportSettingComponent } from './support-setting/support-setting.component';
import { ManageNewsComponent } from './manage-news/manage-news.component';
import { AddNewsComponent } from './add-news/add-news.component';
import { EditNewsComponent } from './edit-news/edit-news.component';
import { ReportSectionComponent } from './report-section/report-section.component';
import { ChatRoomComponent } from './chat-room/chat-room.component';
import { ChatService } from './chat.service';
import { PayoutSaleReprentativePendingComponent } from './payout-sale-reprentative-pending/payout-sale-reprentative-pending.component';
import { ReportRestaurantComponent } from './report-restaurant/report-restaurant.component';
import { TaxSettingComponent } from './tax-setting/tax-setting.component';
import { InquiriesComponent } from './inquiries/inquiries.component';
import { RestaurantReportDetailsComponent } from './restaurant-report-details/restaurant-report-details.component';
import { MasterManagementComponent } from './master-management/master-management.component';
import { WebsiteHomePageComponent } from './website-home-page/website-home-page.component';
import { BannerTextComponent } from './banner-text/banner-text.component';
// import { HomePageHowItWorkComponent } from './home-page-how-it-work/home-page-how-it-work.component';
import { OpenRestaurantPageComponent } from './open-restaurant-page/open-restaurant-page.component';
import { OpenBannerTextComponent } from './open-banner-text/open-banner-text.component';
import { PartnerWithUsComponent } from './partner-with-us/partner-with-us.component';
import { PartnerComponent } from './partner/partner.component';
import { WebsiteRidePageComponent } from './website-ride-page/website-ride-page.component';
import { WebsiteRideBannerComponent } from './website-ride-banner/website-ride-banner.component';
import { WebSocketService } from './websocket.service';
import { AvilableCitiesComponent } from './avilable-cities/avilable-cities.component';
import { AddCityComponent } from './add-city/add-city.component';
import { DatePipe } from '@angular/common';


export function HttpLoaderFactory(http: HttpClient) {
  // console.log('httppp==>>',http) 
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    DashboardComponent,
    MenubarComponent,
    ManageMerchantComponent,
    ChangePasswordComponent,
    MerchantDetailsComponent,
    AddMerchantComponent,
    ManageDriversComponent,
    DriverDetailsComponent,
    LoginVerificationComponent,
    SubmitKycComponent,
    AddNewRoleComponent,
    ManageRoleComponent,
    ViewKycComponent,
    DriverApprovedKycComponent,
    DriverRejectedKycComponent,
    ViewDriverKycComponent,
    PageNotfoundComponent,
    ChangePasswordVerificationComponent,
    CustomerManagmentComponent,
    ViewCustomerDetailsComponent,
    RegisterMerchantComponent,
    WithoutLoginHeaderComponent,
    RegisterDriverComponent,
    DriverSubmitKycComponent,
    ManageStaffComponent,
    FooterComponent,
    AddStaffComponent,
    EditStaffComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CustomerDetailsComponent,
    ManageReportComponent,
    StaffViewComponent,
    RolePermissionComponent,
    ManageSubscriptionComponent,
    OrderHistoryComponent,
    ManageSaleComponent,
    ManageOfferComponent,

    AddSalerepresentativeComponent,

    AddOfferComponent,

    ViewOfferComponent,

    ManageCousinsComponent,

    AddCousinsComponent,

    ManageAllergnComponent,

    AddAllergnComponent,

    EditCusineComponent,

    PayoutDriverComponent,

    PayoutDriverDetailsComponent,

    PayoutSalesDetailsComponent,

    ViewAllerganComponent,

    EditAllerganComponent,

    LegalTermComponent,

    StaticContentComponent,

    OrderViewComponent,

    DeliveryChargeComponent,
    ChangeDeliveryChargesComponent,
    ManageFaqComponent,
    AddFaqComponent,
    EditFaqComponent,
    TopicManagementComponent,
    AddTopicComponent,
    EditTopicComponent,
    HelpQuestionComponent,
    AddHelpQuestionComponent,
    EditHelpQuestionComponent,
    BlogManagementComponent,
    AddBlogComponent,
    EditBlogComponent,
    SupportSettingComponent,
    ManageNewsComponent,
    AddNewsComponent,
    EditNewsComponent,
    ReportSectionComponent,
    ChatRoomComponent,
    PayoutSaleReprentativePendingComponent,
    ReportRestaurantComponent,
    TaxSettingComponent,
    InquiriesComponent,
    RestaurantReportDetailsComponent,
    MasterManagementComponent,
    WebsiteHomePageComponent,
    BannerTextComponent,
    // HomePageHowItWorkComponent,
    OpenRestaurantPageComponent,
    OpenBannerTextComponent,
    PartnerWithUsComponent,
    PartnerComponent,
    WebsiteRidePageComponent,
    WebsiteRideBannerComponent,
    AvilableCitiesComponent,
    AddCityComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    NgxCaptchaModule,
    NgxSpinnerModule,
    RecaptchaModule,
    NgxPopoverImageModule,
    InternationalPhoneNumberModule,
    NgOtpInputModule,


    // CKEditorModule,
    CKEditorModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
    }),
    RatingModule,
    NgMultiSelectDropDownModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgSelectModule,

  ],
  providers: [ExcelService, ChatService, WebSocketService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
