import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { ManageMerchantComponent } from './manage-merchant/manage-merchant.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MerchantDetailsComponent } from './merchant-details/merchant-details.component';
import { AddMerchantComponent } from './add-merchant/add-merchant.component';
import { ManageDriversComponent } from './manage-drivers/manage-drivers.component';
import { DriverDetailsComponent } from './driver-details/driver-details.component';
import { LoginVerificationComponent } from './login-verification/login-verification.component';
import { SubmitKycComponent } from './submit-kyc/submit-kyc.component';
import { AddNewRoleComponent } from './add-new-role/add-new-role.component';
import { ManageRoleComponent } from './manage-role/manage-role.component';
import { ViewKycComponent } from './view-kyc/view-kyc.component';

import { ViewDriverKycComponent } from './view-driver-kyc/view-driver-kyc.component';
import { PageNotfoundComponent } from './page-notfound/page-notfound.component';
import { ChangePasswordVerificationComponent } from './change-password-verification/change-password-verification.component';
import { CustomerManagmentComponent } from './customer-managment/customer-managment.component';
import { ViewCustomerDetailsComponent } from './view-customer-details/view-customer-details.component';
import { RegisterMerchantComponent } from './register-merchant/register-merchant.component';
import { AuthGuard } from './auth.guard';
import { RegisterDriverComponent } from './register-driver/register-driver.component';
import { DriverSubmitKycComponent } from './driver-submit-kyc/driver-submit-kyc.component';
import { ManageStaffComponent } from './manage-staff/manage-staff.component';
import { FooterComponent } from './footer/footer.component';
import { AddStaffComponent } from './add-staff/add-staff.component';
import { EditStaffComponent } from './edit-staff/edit-staff.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { ManageReportComponent } from './manage-report/manage-report.component';
import { StaffViewComponent } from './staff-view/staff-view.component';
import { RolePermissionComponent } from './role-permission/role-permission.component';
import { ManageSubscriptionComponent } from './manage-subscription/manage-subscription.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { ManageSaleComponent } from './manage-sale/manage-sale.component';
import { ManageOfferComponent } from './manage-offer/manage-offer.component';
import { AddSalerepresentativeComponent } from './add-salerepresentative/add-salerepresentative.component';
import { AddOfferComponent } from './add-offer/add-offer.component';
import { ViewOfferComponent } from './view-offer/view-offer.component';
import { ManageCousinsComponent } from './manage-cousins/manage-cousins.component';
import { AddCousinsComponent } from './add-cousins/add-cousins.component';
import { ManageAllergnComponent } from './manage-allergn/manage-allergn.component';
import { AddAllergnComponent } from './add-allergn/add-allergn.component';
import { EditCusineComponent } from './edit-cusine/edit-cusine.component';
import { PayoutDriverComponent } from './payout-driver/payout-driver.component';
import { PayoutDriverDetailsComponent } from './payout-driver-details/payout-driver-details.component';
import { ViewAllerganComponent } from './view-allergan/view-allergan.component';
import { EditAllerganComponent } from './edit-allergan/edit-allergan.component';
import { LegalTermComponent } from './legal-term/legal-term.component';
import { StaticContentComponent } from './static-content/static-content.component';
import { OrderViewComponent } from './order-view/order-view.component';
import { DeliveryChargeComponent } from './delivery-charge/delivery-charge.component';
import { ChangeDeliveryChargesComponent } from './change-delivery-charges/change-delivery-charges.component';
import { ManageFaqComponent } from './manage-faq/manage-faq.component';
import { AddFaqComponent } from './add-faq/add-faq.component';
import { EditFaqComponent } from './edit-faq/edit-faq.component';
import { TopicManagementComponent } from './topic-management/topic-management.component';
import { AddTopicComponent } from './add-topic/add-topic.component';
import { EditTopicComponent } from './edit-topic/edit-topic.component';
import { HelpQuestionComponent } from './help-question/help-question.component';
import { AddHelpQuestionComponent } from './add-help-question/add-help-question.component';
import { EditHelpQuestionComponent } from './edit-help-question/edit-help-question.component';
import { BlogManagementComponent } from './blog-management/blog-management.component';
import { AddBlogComponent } from './add-blog/add-blog.component';
import { EditBlogComponent } from './edit-blog/edit-blog.component';
import { SupportSettingComponent } from './support-setting/support-setting.component';
import { ManageNewsComponent } from './manage-news/manage-news.component';
import { AddNewsComponent } from './add-news/add-news.component';
import { EditNewsComponent } from './edit-news/edit-news.component';
import { ReportSectionComponent } from './report-section/report-section.component';
import { ChatRoomComponent } from './chat-room/chat-room.component';
import { PayoutSaleReprentativePendingComponent } from './payout-sale-reprentative-pending/payout-sale-reprentative-pending.component';
import { PayoutSalesDetailsComponent } from './payout-sales-details/payout-sales-details.component';
import { ReportRestaurantComponent } from './report-restaurant/report-restaurant.component';
import { TaxSettingComponent } from './tax-setting/tax-setting.component';
import { InquiriesComponent } from './inquiries/inquiries.component';
import { RestaurantReportDetailsComponent } from './restaurant-report-details/restaurant-report-details.component';
import { MasterManagementComponent } from './master-management/master-management.component';
import { WebsiteHomePageComponent } from './website-home-page/website-home-page.component';
import { BannerTextComponent } from './banner-text/banner-text.component';
// import { HomePageHowItWorkComponent } from './home-page-how-it-work/home-page-how-it-work.component';
import { OpenRestaurantPageComponent } from './open-restaurant-page/open-restaurant-page.component';
import { OpenBannerTextComponent } from './open-banner-text/open-banner-text.component';
import { PartnerWithUsComponent } from './partner-with-us/partner-with-us.component';
import { PartnerComponent } from './partner/partner.component';
import { WebsiteRidePageComponent } from './website-ride-page/website-ride-page.component';
import { WebsiteRideBannerComponent } from './website-ride-banner/website-ride-banner.component';
import { AvilableCitiesComponent } from './avilable-cities/avilable-cities.component';
import { AddCityComponent } from './add-city/add-city.component';



const routes: Routes = [

  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, },
  { path: 'header', component: HeaderComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'manage-merchant', component: ManageMerchantComponent, canActivate: [AuthGuard] },
  { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard] },
  { path: 'change-password-verification', component: ChangePasswordVerificationComponent },
  { path: 'merchant-details/:id', component: MerchantDetailsComponent, canActivate: [AuthGuard] },
  { path: 'register-merchant', component: RegisterMerchantComponent, canActivate: [AuthGuard] },
  { path: 'manage-drivers', component: ManageDriversComponent, canActivate: [AuthGuard] },
  { path: 'driver-details/:id', component: DriverDetailsComponent, canActivate: [AuthGuard] },
  { path: 'login-verification/:id', component: LoginVerificationComponent },
  { path: 'submit-kyc/:id', component: SubmitKycComponent, canActivate: [AuthGuard] },
  { path: 'add-new-role', component: AddNewRoleComponent, canActivate: [AuthGuard] },
  { path: 'manage-role', component: ManageRoleComponent },
  { path: 'view-kyc/:id', component: ViewKycComponent, canActivate: [AuthGuard] },
  { path: 'driver-view-kyc/:id', component: ViewDriverKycComponent, canActivate: [AuthGuard] },
  { path: "customer-mngt", component: CustomerManagmentComponent, canActivate: [AuthGuard] },
  { path: 'sale-representative-details/:id', component: ViewCustomerDetailsComponent, canActivate: [AuthGuard] },
  { path: "page-notfound", component: PageNotfoundComponent, canActivate: [AuthGuard] },
  { path: "customer-managment", component: CustomerManagmentComponent, canActivate: [AuthGuard] },
  { path: 'register-driver', component: RegisterDriverComponent, canActivate: [AuthGuard] },
  { path: 'driver-submit-kyc/:id', component: DriverSubmitKycComponent, canActivate: [AuthGuard] },
  { path: 'manage-staff', component: ManageStaffComponent, canActivate: [AuthGuard] },
  { path: 'add-staff', component: AddStaffComponent, canActivate: [AuthGuard] },
  { path: 'edit-staff/:id', component: EditStaffComponent, canActivate: [AuthGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'customer-details/:id', component: CustomerDetailsComponent, canActivate: [AuthGuard] },
  { path: 'manage-report', component: ManageReportComponent, canActivate: [AuthGuard] },
  { path: 'staff-view/:id', component: StaffViewComponent, canActivate: [AuthGuard] },
  { path: 'role-permission/:id', component: RolePermissionComponent, canActivate: [AuthGuard] },
  { path: 'manage-subscription', component: ManageSubscriptionComponent, canActivate: [AuthGuard] },
  { path: 'order-history', component: OrderHistoryComponent, canActivate: [AuthGuard] },
  { path: 'manage-sale', component: ManageSaleComponent, canActivate: [AuthGuard] },
  { path: 'manage-offer', component: ManageOfferComponent, canActivate: [AuthGuard] },
  { path: 'add-offer', component: AddOfferComponent, canActivate: [AuthGuard] },
  { path: 'add-sale-representative', component: AddSalerepresentativeComponent },
  { path: 'view-offer-details/:id', component: ViewOfferComponent, canActivate: [AuthGuard] },
  { path: 'manage-cuisine', component: ManageCousinsComponent, canActivate: [AuthGuard] },
  { path: 'add-cuisine', component: AddCousinsComponent, canActivate: [AuthGuard] },
  { path: 'manage-allergen', component: ManageAllergnComponent, canActivate: [AuthGuard] },
  { path: 'add-allergen', component: AddAllergnComponent, canActivate: [AuthGuard] },
  { path: 'edit-cuisine/:id/:language', component: EditCusineComponent },
  { path: 'payout-driver', component: PayoutDriverComponent, canActivate: [AuthGuard] },
  { path: 'payout-driver-details/:id', component: PayoutDriverDetailsComponent, canActivate: [AuthGuard] },
  { path: 'view-allergan/:id/:val', component: ViewAllerganComponent, canActivate: [AuthGuard] },
  { path: 'edit-allergan/:id/:language', component: EditAllerganComponent, canActivate: [AuthGuard] },
  { path: 'legal-terms', component: LegalTermComponent, canActivate: [AuthGuard] },
  { path: 'service/:type/:language/:id', component: StaticContentComponent, canActivate: [AuthGuard] },
  { path: 'order-view', component: OrderViewComponent, canActivate: [AuthGuard] },
  { path: 'delivery-charge', component: DeliveryChargeComponent, canActivate: [AuthGuard] },
  { path: 'change-delivery-charges', component: ChangeDeliveryChargesComponent, canActivate: [AuthGuard] },
  { path: 'manage-faq', component: ManageFaqComponent, canActivate: [AuthGuard] },
  { path: 'add-faq', component: AddFaqComponent, canActivate: [AuthGuard] },
  { path: 'edit-faq', component: EditFaqComponent, canActivate: [AuthGuard] },
  { path: 'topic-management', component: TopicManagementComponent },
  { path: 'add-topic', component: AddTopicComponent, canActivate: [AuthGuard] },
  { path: 'edit-topic', component: EditTopicComponent, canActivate: [AuthGuard] },
  { path: 'help-question', component: HelpQuestionComponent, canActivate: [AuthGuard] },
  { path: 'add-help-question', component: AddHelpQuestionComponent, canActivate: [AuthGuard] },
  { path: 'edit-help-question', component: EditHelpQuestionComponent, canActivate: [AuthGuard] },
  { path: 'blog-management', component: BlogManagementComponent, canActivate: [AuthGuard] },
  { path: 'add-blog', component: AddBlogComponent, canActivate: [AuthGuard] },
  { path: 'edit-blog', component: EditBlogComponent, canActivate: [AuthGuard] },
  { path: 'support-setting', component: SupportSettingComponent, canActivate: [AuthGuard] },
  { path: 'manage-news', component: ManageNewsComponent, canActivate: [AuthGuard] },
  { path: 'add-news', component: AddNewsComponent, canActivate: [AuthGuard] },
  { path: 'edit-news', component: EditNewsComponent, canActivate: [AuthGuard] },
  { path: 'report-section', component: ReportSectionComponent, canActivate: [AuthGuard] },
  { path: 'chat-room', component: ChatRoomComponent, canActivate: [AuthGuard] },
  { path: 'payout-sale-reprentative-pending', component: PayoutSaleReprentativePendingComponent, canActivate: [AuthGuard] },
  { path: 'payout-sales-details/:id', component: PayoutSalesDetailsComponent, canActivate: [AuthGuard] },
  { path: 'report-restaurant', component: ReportRestaurantComponent, canActivate: [AuthGuard] },
  { path: 'tax-setting', component: TaxSettingComponent, canActivate: [AuthGuard] },
  { path: 'inquiries', component: InquiriesComponent, canActivate: [AuthGuard] },
  { path: 'restaurant-report-details', component: RestaurantReportDetailsComponent, canActivate: [AuthGuard] },
  { path: 'master-management', component: MasterManagementComponent, canActivate: [AuthGuard] },
  { path: "website-home-page", component: WebsiteHomePageComponent, canActivate: [AuthGuard] },
  { path: "banner-text", component: BannerTextComponent, canActivate: [AuthGuard] },
  // { path: 'home-page-how-it-work', component: HomePageHowItWorkComponent, canActivate: [AuthGuard] },
  { path: 'open-restaurant-page', component: OpenRestaurantPageComponent, canActivate: [AuthGuard] },
  { path: 'open-banner-text', component: OpenBannerTextComponent, canActivate: [AuthGuard] },
  { path: 'partner-with-us', component: PartnerWithUsComponent, canActivate: [AuthGuard] },
  { path: 'partner', component: PartnerComponent, canActivate: [AuthGuard] },
  { path: 'website-ride-page', component: WebsiteRidePageComponent, canActivate: [AuthGuard] },
  { path: 'website-ride-banner', component: WebsiteRideBannerComponent, canActivate: [AuthGuard] },
  { path: 'avilable-country', component: AvilableCitiesComponent, canActivate: [AuthGuard] },
  { path: 'add-country', component: AddCityComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'page-notfound' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
