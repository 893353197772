import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';




@Injectable()
export class WebSocketService {
  // merchantId: string;
  chatSocket: WebSocket;
  socketConnection = new Subject<any>();
  changeMessageStatus: boolean;
  // chatBaseUrl = 'ws://13.250.224.209:8003/ws/support/sender/44'
  // chatBaseUrl = "ws://172.16.1.16:8001/ws/support/sender/1"
  chatBaseUrl = "wss://py-khanaexpress.mobiloitte.org/ws/support/sender/1"
  adminId: string;
  constructor() {

  }

  initchatSocket() {
    // if(!localStorage.getItem('merchantId')){
    //   return;
    // }
    // this.merchantId = "403"
    this.adminId = localStorage.getItem('adminId')
    this.chatSocket = new WebSocket(this.chatBaseUrl);
    let self = this;
    this.chatSocket.addEventListener('open', function (event) {
      console.log("init chat socket open response=>", event)
      self.socketConnection.next('online');
      // console.log('chat socket online======wsExchange=====', event);
      self.changeMessageStatus = false;
      self.getChatMessage();
    });
    this.chatSocket.addEventListener('close', function (event) {
      console.log("init chat socket close response=>", event)
      self.socketConnection.next('offline');
      // console.log('chat socket ofline======wsExchange=====', event);
      self.initchatSocket();
    });
  }

  recievedMessage = new BehaviorSubject(``)


  getChatMessage() {

    this.chatSocket.addEventListener('message', (event) => {
      console.log("socket get chat message response=>", event)
      let data = JSON.parse(event.data);
      console.log("socket get chat message response=>", event)

      this.recievedMessage.next(data)
      if (data.id) {
        // if (data.sender == this.merchantId) {
        if (data.sender == this.adminId) {
          this.changeMessageStatus = true;
        } else {
          this.changeMessageStatus = false;
        }
        this.sendMessage(data)
      }

    })
  }
  sendMessage(message) {
    return this.socketConnection.next({ text: message })
  }
  /** to get message */
  receiveMessage(): Observable<any> {
    console.log("message")
    return this.socketConnection.asObservable();
  }
}