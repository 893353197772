import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as io from 'socket.io-client';

@Injectable()
export class ChatService {
  // socket: WebSocket;
  socket = new WebSocket('wss://py-khanaexpress.mobiloitte.org/ws/support/sender/403');
  // socket = new WebSocket('ws://172.16.1.16:8001/ws/support/sender/44') // aftab sir;

  constructor() {
   
 
  }

  onopen = function () {
    console.log("Connected to chat socket");
  };

  onmessage = function(event) {
    console.log("WebSocket message received:", event);
  };
  onclose = function(event) {
    console.log("WebSocket is closed now.");//sdfdsfsdfsfsdfsdfsdfds
  };
  
}
