import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../api-service.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-delivery-charge',
  templateUrl: './delivery-charge.component.html',
  styleUrls: ['./delivery-charge.component.css']
})
export class DeliveryChargeComponent implements OnInit {
  fixCharge:any
  radiusId:any;
  radiuscharge:any;
  fixChargeId:any;


  // tempArray : any = []

  constructor(private service: ApiServiceService, private router: Router, private spinner: NgxSpinnerService) {
    
  }

  ngOnInit() {
   
    this.getAllcharges()
  }
  ngAfterViewInit() {
    // this.getAllcharges()
  }

  getAllcharges() {
    let index = 0
    this.service.getApi('api/delivery-charges', 1).subscribe(res => {
      // alert(JSON.stringify(res.body.id))
    
      if (res.status == 200) {
        
        this.fixCharge = res.body[0]
        console.log("---------",this.fixCharge)
        // this.tempArray = res.body
      //  alert(typeof(this.fixCharge))
      // for(let i of this.fixCharge){
      //   this.tempArray[index] = i
      //   index ++
      //   console.log(i)
      // // }
      //   console.log("id---",this.tempArray[0])

          if (this.fixCharge.type == 'Radius') {
            this.radiuscharge= this.fixCharge.type 
            this.radiusId = this.fixCharge.id
            // this.router.navigate(['/change-delivery-charges',{queryParam:{radiusId : this.radiusId}}])
           
          }
          else {
            this.fixChargeId = this.fixCharge.id

          }
    
        // for (var i in this.fixCharge) {
        //   console.log("id---",this.fixCharge[i].id)

        //   if (this.fixCharge[i].type == 'Radius') {
        //     this.radiusId = this.fixCharge[i].id
        //     console.log(this.fixChargeId, "fixChargeId")
        //   }
        //   else {
        //     this.fixChargeId = this.fixCharge[i].id
        //     console.log(this.radiusId, "radiousId")
        //   }
        // }
        console.log(this.fixChargeId, "fixChargeId")
        console.log(this.radiusId, "radiousId")
        console.log('-------->',this.fixCharge)
        console.log("radius", this.radiuscharge)
      }
    })
  }



}
