import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ApiServiceService } from '../api-service.service';
import { WebSocketService } from '../websocket.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-chat-room',
  templateUrl: './chat-room.component.html',
  styleUrls: ['./chat-room.component.css']
})

export class ChatRoomComponent implements OnInit, OnDestroy {
  // selectedTab: any='1a';
  @ViewChild('scrollMe', { static: true }) private myScrollContainer: ElementRef;
  selectedTab: any;
  loadList: boolean;
  getChatListData: any = [];
  loadHistory: boolean;
  getHistoryUser: any;
  userChatHistory: any = [];
  textMessage: any;
  lastSendMessage: any;
  lastSendStatus: boolean;
  rearchCustomer: any;
  searchCustomerList: any = [];
  chatMessageForm: FormGroup;
  sendImgUrl: any;
  chatType: any; //chat between merchat-admin customer-admin or driver/admin
  currentRoomId: any = localStorage.getItem('chatRoomId')
  sender_Img: any;
  constructor(private service: ApiServiceService, public webSocket: WebSocketService, private fb: FormBuilder) {
    this.webSocket.initchatSocket();
    this.service.showSpinner();
    this.webSocket.receiveMessage().subscribe((res: any) => {
      if (res == 'online') {
        if (this.getChatListData.length > 0) {
          // let req = {
          //   'action': 'join',
          //   'room': {
          //     'id': this.getHistoryUser.id,
          //     // 'sender_id': Number(this.webSocket.merchantId),
          //     'sender_id': Number(this.webSocket.adminId),
          //     'receiver_id': this.getHistoryUser.sender,
          //     'chat_type': this.getHistoryUser.chat_type,
          //   }
          // }
          // this.webSocket.chatSocket.send(JSON.stringify(req));
        } else {
          this.getChatList();
        }
      } else if (res.text) {
        this.userChatHistory.push(res.text);
        // console.log(this.userChatHistory)
        // this.userChatHistory =  this.userChatHistory.reduceRight((r, a)=>{
        //     r.some(function (b) { return a.id === b.id; }) || r.push(a);
        //     return r;
        //   }, []);  
        this.scrollEnd();
      }
      if (this.webSocket.changeMessageStatus == true) {
        this.lastSendStatus = false;
        this.lastSendMessage = '';
      }
    })
  }
  myId : any
  ngOnInit() {
    this.recieveMessage()
    if (this.webSocket.changeMessageStatus == true || this.webSocket.changeMessageStatus == false) {
      this.getChatList();
    }
    this.makeActive('a-m');
    this.chatMessageFormValidation();
    this.myId = localStorage.getItem("myUserId")
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    if (this.getChatListData.length == 0) {
      return;
    } else {
      let req = {
        'action': 'leave',
        'room': {
          'id': this.getHistoryUser.id
        }
      }
      this.webSocket.chatSocket.send(JSON.stringify(req));
    }
  }

  // -------- chat message form validation ------------------- //
  chatMessageFormValidation() {
    this.chatMessageForm = this.fb.group({
      textMessage: new FormControl('', Validators.compose([Validators.required])),
      // sendDocument:new FormControl('')
    })
  }


  /**
   * 
   * @param event 
   * event are merchat, customer, driver
   * a-m for merchat, 2a for customer & 3a for driver 
   */
  makeActive(event) {
    this.selectedTab = event;
    this.chatType = event
    this.getChatListData = []
    this.userChatHistory = [];
    this.getChatList()
  }
  interval : any
  // ---------------- get chat list ------------------------- //
  getChatList() {
    this.loadList = true;
    this.service.showSpinner()
    this.service.getApi(`chat/chat-list?chat_type=${this.chatType}`, 1).subscribe((data: any) => {
      this.service.hideSpinner()
      this.loadList = false;
      if (data.status == 200) {
        this.getChatListData = data.body.rooms;
        console.log(this.getChatListData, 'fkgherwgiktehrg')
        if (this.getChatListData.length == 0) {
          return;
        } else {
          this.service.hideSpinner()
          if (this.interval) {
            clearInterval(this.interval);
          }
         this.interval = setInterval(() => {
            if (this.getHistoryUser) {
              this.getChatHistory(this.getHistoryUser); // Pass the last active chat room/item
            }
          }, 1000); // 1000 
          this.getChatHistory(this.getChatListData[0]);

        }
      }
    }, (err) => {
      this.service.hideSpinner()
      this.getChatHistory(this.getChatListData[0]);
    });
  }

  // ------------------ update chat list ------------------- //
  updateChatList() {
    this.service.getApi(`chat/chat-list?chat_type=${this.chatType}`, 1).subscribe((data: any) => {
      if (data.status == 200) {
        this.getChatListData = data.body.data;
      }
    });
  }


  recieveMessage() {
    this.webSocket.recievedMessage.subscribe((res: any) => {
      console.log("1 res", (res))
      console.log("second 1", this.getChatListData)
      this.userChatHistory.push(res)
      this.getChatListData.forEach(element => {
        console.log("element===>", element.id)
        if (res.room_id == element.id) {
          this.userChatHistory.push(res)
        }
      });
      if (res.room_id == localStorage.getItem('chatRoomId')) {
        this.userChatHistory.push(res)
        this.lastSendStatus = false
      }
      console.log("third one", this.userChatHistory)
    })
  }


  // searchCustomerFunction() {
  //   this.getChatListData = []
  //   this.service.getApi('chat/chat-list?chat_type=m-c&search=' + this.rearchCustomer, 1).subscribe((data: any) => {
  //     if (data.status == 200) {
  //       this.getChatListData = data.body.data;
  //     }
  //   });
  // }

  // ------------ get chat history ------------------------ //
  roomId : any
  senderId : any
  reciverId : any
  getChatHistory(item) {
    console.log(item)
    this.sender_Img = item.sender.profile_image
    console.log(this.sender_Img)
    localStorage.setItem('chatRoomId', item.id)
    this.roomId = item.id
    this.senderId = item.receiver
    this.reciverId =  item.sender
    this.lastSendMessage = '';
    this.lastSendStatus = false;
    // if (this.getHistoryUser) {
    //   if (this.getHistoryUser.id == item.id) {
    //     return;
    //   }

    // }
    this.loadHistory = true;
    this.getHistoryUser = item;
    // this.userChatHistory = [];
    // this.service.showSpinner()
    this.service.getApi('chat/chat-history/' + item.id, 1).subscribe((data: any) => {
      // this.service.hideSpinner()
      this.loadHistory = false;
      if (data.status == 200) {
        // this.updateChatList();//
        // let req = {
        //   'action': 'join',
        //   'room': {
        //     'id': this.getHistoryUser.id,
        //     // 'sender_id': Number(this.webSocket.merchantId),
        //     'sender_id': Number(this.webSocket.adminId),
        //     'receiver_id': this.getHistoryUser.sender,
        //     'chat_type': this.getHistoryUser.chat_type,
        //     // 'support': this.getChatListData[0].id,
        //   }
        // }
        // this.webSocket.chatSocket.send(JSON.stringify(req));
        let list = data.body.chat_messages.reverse();
        this.userChatHistory = list;
        this.userChatHistory.map(ele => {
          ele['sender_id'] = ele.sender;
          ele['receiver_id'] = ele.receiver
        })
        this.scrollEnd();
      }
    });
  }

  // ----------------- send message ----------------------- //
  sendMassageToCustomer() {
    if (this.chatMessageForm.value.textMessage || this.sendImgUrl) {
      
      let req = {
        'room_id': this.roomId,
        "message": this.chatMessageForm.value.textMessage ? this.chatMessageForm.value.textMessage : '',
        // 'sender_id': Number(this.webSocket.merchantId),
        'sender_id': this.myId,
        'receiver_id': 'id' in this.reciverId ? this.reciverId.id : this.reciverId,
        "image": this.sendImgUrl ? this.sendImgUrl : '',
        "message_type": "text"
      }
      this.webSocket.chatSocket.send(JSON.stringify(req));
      this.userChatHistory.push(req)
      console.log(req)
      console.log(this.userChatHistory)
      this.getChatList
      this.lastSendMessage = {
        image: this.sendImgUrl ? this.sendImgUrl : '',
        message: this.chatMessageForm.value.textMessage ? this.chatMessageForm.value.textMessage : '',
        message_type: "text",
        read: true,
        receiver: this.userChatHistory[0].sender_id,
        // sender: Number(this.webSocket.merchantId),
        sender: Number(this.webSocket.adminId),
      }
      this.lastSendStatus = true;
      this.chatMessageForm.reset();
      this.scrollEnd();

      // for update list (move chat person at the top of chat list)
      if(this.lastSendMessage!="" && this.getChatListData.length!=0){
        if (this.getChatListData[0].id != this.getHistoryUser.id) {
          console.log("Not Matched Update list")
          this.updateChatList()
        } else {
          console.log("Match don't Update.")
        }
      }
      else{
        return
      }
    }
  }

  // ------------------- scroll chat container to bottom --------------------------- //
  scrollEnd() {
    setTimeout(() => {
      try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      } catch (err) { }
    }, 10);
  }

  // ----------------- read message ---------------------------- //
  readMessage() {
    if (!this.getHistoryUser) {
      return;
    }
    if (this.getHistoryUser.unread_msgs == 0) {
      return;
    }
    this.service.getApi('chat/read-msg/' + this.getHistoryUser.id, 1).subscribe((data: any) => {
      if (data.status == 200) {
        this.updateChatList();
      }
    });
  }


  // ------------------------- Upload Restaurant image-----------------------//
  // senDocumentFunction(event) {
  //   if (event.target.files && event.target.files[0]) {
  //     // upload image function
  //     var FileSize = event.target.files[0].size / 1024 / 1024; // in MB
  //     var type = event.target.files[0].type;
  //     if(type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
  //       if (FileSize > 2) {
  //         this.service.toastErr("file exceed size 2 MB");
  //       } else {
  //         this.lastSendStatus = true;
  //         var file = <File>event.target.files[0]
  //         const fileData = new FormData();
  //         fileData.append('image', file);
  //         // this.service.showSpinner();
  //         this.service.imageUpload('merchant/image', fileData).subscribe((data: any) => {
  //           // this.service.hideSpinner();
  //           if (data.status == 200) {
  //             this.sendImgUrl = data.body.file_name
  //             this.sendMassageToCustomer()
  //           }
  //         })
  //       }        
  //     }else{
  //       this.service.toastErr('Select only jpg,jpeg and png file.')
  //     }
  //   }
  // }
}
