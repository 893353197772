import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-merchant',
  templateUrl: './add-merchant.component.html',
  styleUrls: ['./add-merchant.component.css']
})
export class AddMerchantComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
