import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../api-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.css']
})
export class MenubarComponent implements OnInit {
  permissions: any = [];
  Blog_Management: boolean = false;
  Support_Chat: boolean = false;
  Manage_Cuisines: boolean = false;
  Customers_Management: boolean = false;
  Dashboard: boolean = false;
  Delivery_Charge: boolean = false;
  Drivers_Management: boolean = false;
  Manage_FAQ: boolean = false;
  Manage_Help_Questions: boolean = false;
  Inquiries: boolean = false;
  Terms_Conditions: boolean = false;
  Master_Management: boolean = false;
  Merchant_Management: boolean = false;
  News_Management: boolean = false;
  Order_History: boolean = false;
  Payout_Driver: boolean = false;
  Report_Management: boolean = false;
  Report_Restaurant: boolean = false;
  Sales_Representative: boolean = false;
  Sales_Representative_Payout: boolean = false;
  Staff_Management: boolean = false;
  Subscription_Management: boolean = false;
  Support_Settings: boolean = false;
  Tax_Settings: boolean = false;
  Roles_Management: boolean = false;
  Offers_Management: boolean = false;
  Manage_Allergans: boolean = false;



  constructor(private service: ApiServiceService, private router: Router) { }

  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem('permissions'))
    // console.log(this.permissions)
    // console.log('permissionsddss', this.permissions[0].has_permission)
    this.permissionsFunction()

  }
  //   reload(){
  //     this.service.spiners()
  //   location.reload();
  // }

  // rolemanage(){
  // this.router.navigate(['manage-role'])
  // // window.location.reload();
  // }
  // onClick(check){
  //   //    console.log(check);
  //       if(check==1){
  //         this.tab = 'tab1';
  //       }else if(check==2){
  //         this.tab = 'tab2';
  //       }else{
  //         this.tab = 'tab3';AsaSasasasaSas
  //       }    

  //   }aSasasasasasaSassaasAsasas
  // }
  permissionsFunction() {
    for (let i in this.permissions) {
      console.log(this.permissions[i].name, this.permissions[i].has_permission)
      if (this.permissions[i].name == "Blog Management" && this.permissions[i].has_permission == true) {
        this.Blog_Management = true
        // console.log(this.Blog_Management)
      }
      if (this.permissions[i].name == "Support Chat" && this.permissions[i].has_permission == true) {
        this.Support_Chat = true
        // console.log(this.Support_Chat)
      }
      if (this.permissions[i].name == "Manage Cuisines" && this.permissions[i].has_permission == true) {
        this.Manage_Cuisines = true
        // console.log(this.Manage_Cuisines)
      }
      if (this.permissions[i].name == "Customers Management" && this.permissions[i].has_permission == true) {
        this.Customers_Management = true
        // console.log(this.Customers_Management)
      }
      if (this.permissions[i].name == "Dashboard" && this.permissions[i].has_permission == true) {
        this.Dashboard = true
        // console.log(this.Dashboard)
      }
      if (this.permissions[i].name === "Delivery Charge" && this.permissions[i].has_permission == true) {
        this.Delivery_Charge = true
        // console.log(this.Delivery_Charge)
      }
      if (this.permissions[i].name === "Drivers Management" && this.permissions[i].has_permission == true) {
        this.Drivers_Management = true
        // console.log(this.Drivers_Management)
      }
      if (this.permissions[i].name === "Manage FAQ" && this.permissions[i].has_permission == true) {
        this.Manage_FAQ = true
        // console.log(this.Manage_FAQ)
      }
      if (this.permissions[i].name === "Manage Help Questions" && this.permissions[i].has_permission == true) {
        this.Manage_Help_Questions = true
        // console.log(this.Manage_Help_Questions)
      }
      if (this.permissions[i].name === "Inquiries" && this.permissions[i].has_permission == true) {
        this.Inquiries = true
        // console.log(this.Inquiries)
      }
      if (this.permissions[i].name === "Legal, Terms & Conditions" && this.permissions[i].has_permission == true) {
        this.Terms_Conditions = true
        // console.log(this.Terms_Conditions)
      }
      if (this.permissions[i].name === "Master Management" && this.permissions[i].has_permission == true) {
        this.Master_Management = true
        // console.log(this.Master_Management)
      }
      if (this.permissions[i].name === "Merchant Management" && this.permissions[i].has_permission == true) {
        this.Merchant_Management = true
        // console.log(this.Merchant_Management)
      }
      if (this.permissions[i].name === "News Management" && this.permissions[i].has_permission == true) {
        this.News_Management = true
        // console.log(this.News_Management)
      }
      if (this.permissions[i].name === "Offers Management" && this.permissions[i].has_permission == true) {
        this.Offers_Management = true
        // console.log(this.Offers_Management)
      }
      if (this.permissions[i].name === "Order History" && this.permissions[i].has_permission == true) {
        this.Order_History = true
        // console.log(this.Order_History)
      }
      if (this.permissions[i].name === "Payout Driver" && this.permissions[i].has_permission == true) {
        this.Payout_Driver = true
        // console.log(this.Payout_Driver)
      }
      if (this.permissions[i].name === "News Management" && this.permissions[i].has_permission == true) {
        this.News_Management = true
        // console.log(this.News_Management)
      }
      if (this.permissions[i].name === "Report Management" && this.permissions[i].has_permission == true) {
        this.Report_Management = true
        // console.log(this.Report_Management)
      }
      if (this.permissions[i].name === "Report Restaurant" && this.permissions[i].has_permission == true) {
        this.Report_Restaurant = true
        // console.log(this.Report_Restaurant)
      }
      if (this.permissions[i].name === "Roles Management" && this.permissions[i].has_permission == true) {
        this.Roles_Management = true
        // console.log(this.Roles_Management)
      }
      if (this.permissions[i].name === "Sales Representative" && this.permissions[i].has_permission == true) {
        this.Sales_Representative = true
        // console.log(this.Sales_Representative)
      }
      if (this.permissions[i].name === "Sales Representative Payout" && this.permissions[i].has_permission == true) {
        this.Sales_Representative_Payout = true
        // console.log(this.Sales_Representative_Payout)
      }
      if (this.permissions[i].name === "Staff Management" && this.permissions[i].has_permission == true) {
        this.Staff_Management = true
        // console.log(this.Staff_Management)
      }
      if (this.permissions[i].name === "Subscription Management" && this.permissions[i].has_permission == true) {
        this.Subscription_Management = true
        // console.log(this.Subscription_Management)
      }

      if (this.permissions[i].name === "Support Settings" && this.permissions[i].has_permission == true) {
        this.Support_Settings = true
        // console.log(this.Support_Settings)
      }
      if (this.permissions[i].name === "Tax Settings" && this.permissions[i].has_permission == true) {
        this.Manage_Allergans = true
        // console.log(this.Manage_Allergans)
      }
      if (this.permissions[i].name === "Manage Allergans" && this.permissions[i].has_permission == true) {
        this.Manage_Allergans = true
        // console.log(this.Manage_Allergans)
      }
    }
  }




}
