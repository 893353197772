import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { ApiServiceService } from '../api-service.service';

import { NgxSpinnerService } from 'ngx-spinner';

@Component({

  selector: 'app-dashboard',

  templateUrl: './dashboard.component.html',

  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {

  dashboadData: any;

  constructor(private router: Router, private service: ApiServiceService, private spinner: NgxSpinnerService) {


  }

  ngOnInit() {

    localStorage.removeItem('keys')

    localStorage.removeItem('tokens')

    this.dashboard()

  }
  // dsfdsdsfsdgfgdfgfgfg

  // ----------------------Dashbord Api---------------------------//

  dashboard() {

    this.spinner.show()

    this.service.getApi('api/dashboard', 1).subscribe((data: any) => {

      this.dashboadData = data.body;

      if (data.status == 200) {

        this.spinner.hide()

      }

    }, err => {
      if (err.status == 403 || err.status == 401) {

        this.spinner.hide()

        this.service.logout();

      }

      else if (err.status == 400) {

        this.spinner.hide()

      }
      else if (err.status == 500) {

        this.spinner.hide()

        this.service.toastErr(err.statusText)

      }
      this.spinner.hide()
    })
  }
}
