import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../api-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

@Component({
  selector: 'app-avilable-cities',
  templateUrl: './avilable-cities.component.html',
  styleUrls: ['./avilable-cities.component.css']
})
export class AvilableCitiesComponent implements OnInit {
  city: any;
  limit: number = 10
  page: number = 1
  total: number
  varificationCode: any;
  showOtpComponent = true;
  errorMessage: any;
  id: any;
  constructor(private service: ApiServiceService, private router: Router, private tostr: ToastrService, private forrmBuilder: FormBuilder, private activateRouter: ActivatedRoute, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.getCity()
  }
  pagination(page) {
    this.page = page
    this.getCity()
  }
  getCity() {
    this.spinner.show()
    this.service.getApi(`/api/available-cities?page=${this.page}`, 1).subscribe(res => {
      if (res.status == 200) {
        this.spinner.hide()
        this.city = res.body.results
        this.limit = res.body.count
      }
    }, err => {
      if (err.status == 500) {
        this.spinner.hide()
        this.service.toastErr('Internal server error.')
      } else if (err.status == 403 || err.status == 401) {
        this.spinner.hide()
        this.service.logout()
        this.service.toastErr(err.error.message)
      } else if (err.status == 400) {
        this.spinner.hide()
        this.service.toastErr(err.error.message)
      } else if (err.status == 404) {
        this.spinner.hide()
        this.service.toastErr(err.error.message)

      }
    })
  }


  deleteid(id) {
    this.id = id
    $('#exampleModal2').modal({ backdrop: 'static', keyboard: false })
  }
  deletemodal() {
    $('#exampleModal2').modal('hide')
    $('#googleauth').modal({ backdrop: 'static', keyboard: false })

  }
  delete() {
    this.spinner.show()
    this.service.delete(`/api/available-cities/${this.id}`, this.id, 1).subscribe(res => {
      if (res.status == 200) {
        this.spinner.hide()
        this.city = res.body.results
        this.limit = res.body.count
      }
    }, err => {
      if (err.status == 500) {
        this.spinner.hide()
        this.service.toastErr('Internal server error.')
      } else if (err.status == 403 || err.status == 401) {
        this.spinner.hide()
        this.service.logout()
        this.service.toastErr(err.error.message)
      } else if (err.status == 400) {
        this.spinner.hide()
        this.service.toastErr(err.error.message)
      } else if (err.status == 404) {
        this.spinner.hide()
        this.service.toastErr(err.error.message)

      }
    })
  }

  // google auth
  onOtpChange(value) {
    this.varificationCode = value
  }

  onConfigChange() {
    this.showOtpComponent = false;
    this.varificationCode = null;
    setTimeout(() => {
      this.showOtpComponent = true;
    }, 0);
  }
  verify() {
    let data = {
      "code": this.varificationCode
    }
    this.service.postApi('api/google-auth-step-verification', data, 1).subscribe((res) => {
      if (res.status == 200) {
        this.onConfigChange()
        $('#googleauth').modal('hide')
        this.delete()
      }
      else {
        this.onConfigChange()
        this.tostr.error()
      }
    }, err => {
      if (err.status == 403 || err.status == 401) {
        this.onConfigChange()
        this.service.logout();
      }
      else if (err.status == 400) {
        this.onConfigChange()
        this.errorMessage = err.error.message
        // this.tostr.error(err.error.message)
      }
    })
  }

}
